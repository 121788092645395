<template>
    <div>
        <div class="banner">
            <img src="~@/assets/images/lianxi_banner.png" alt="">
        </div>
        <div class="title_tabs">
            <div class="title_inner">
                <div :class="['btn',isActive==0?'active':'']" @click="handleTabsChange(0)">
                    <img src="~@/assets/images/liyan.png" alt="">
                    <div class="btn_inner">
                        <i></i>
                        <span>在线留言</span>
                    </div>
                </div>
                <div :class="['btn',isActive==1?'active':'']" @click="handleTabsChange(1)">
                    <img src="~@/assets/images/liyan.png" alt="">
                    <div class="btn_inner">
                        <i></i>
                        <span>人才招聘</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="you_save">
            <div class="inner">
                <el-row class="leave_work" v-if="isActive==0">
                    <el-col :span="12">
                        <SubTitle class="leave_work_title" titleColor="#333333" subColor="#333333">
                            <template v-slot:title>在线留言</template>
                            <template v-slot:sub>ONLINE MESSAGE</template>
                        </SubTitle>
                        <p class="remak">您可以在这里向往我们发送留言，我们尽快为您提供帮助</p>
                        <ul class="xinxi_box">
                            <li>
                                <img src="~@/assets/images/liuyan_weizhi.png" alt="">
                                <div class="xinxi">
                                    <p class="xinxi_title">公司地址</p>
                                    <p class="xinxi_text">郑州市经开区第九大街经南二路正商经开广场6号楼3层</p>
                                </div>
                            </li>
                            <li>
                                <img src="~@/assets/images/liuyan_dianhua.png" alt="">
                                <div class="xinxi">
                                    <p class="xinxi_title">咨询电话</p>
                                    <p class="xinxi_text">0371-56062765 | 186-9734-2233</p>
                                </div>
                            </li>
                            <li>
                                <img src="~@/assets/images/liuyan_youxiang.png" alt="">
                                <div class="xinxi">
                                    <p class="xinxi_title">邮箱</p>
                                    <p class="xinxi_text">mis01@qq.com</p>
                                </div>
                            </li>
                        </ul>
                    </el-col>
                    <el-col :span="12">
                        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="" class="demo-ruleForm">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item prop="name">
                                        <el-input v-model="form.name" placeholder="您的姓名*"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="tel">
                                        <el-input v-model="form.tel" placeholder="联系方式*"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="type">
                                        <el-select v-model="form.type" style="width:100%;" placeholder="意向菜单*">
                                        <el-option :label="item.dictLabel" :value="item.dictValue" v-for="item in typeList" :key="item.dictValue"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="danwei">
                                        <el-input v-model="form.danwei" placeholder="单位名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item prop="content">
                                        <el-input type="textarea" :rows="8" v-model="form.content" placeholder="请留言*"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-button style="width:100%;" type="primary" @click="submitForm('ruleForm')" :loading="loading">提 交</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button style="width:100%;" type="info" @click="resetForm('ruleForm')">重 置</el-button>
                                </el-col>
                            </el-row>

                        </el-form>
                    </el-col>
                </el-row>
                <div class="recruit" v-if="isActive==1">
                    <ul v-if="recruitList.length>0">
                        <li v-for="item in recruitList" :key="item.id">
                            <div class="title">
                                <span class="label">急</span>
                                {{ item.post }}
                            </div>
                            <div class="info">
                                <span>{{ item.z==0?'兼职':'全职' }}</span>
                                <span class="line">|</span>
                                <span>{{ item.postType }}</span>
                            </div>
                            <div class="address">
                                <div class="address_left">
                                    <span>{{ item.address[0] }}</span>
                                    <span class="line">·</span>
                                    <span>{{ item.address[1] }}</span>
                                </div>
                                <div class="time">发布时间：{{ item.time }}</div>
                            </div>
                        </li>
                    </ul>
                    <div class="page_box">
                        <el-pagination
                        v-if="total>0"
                        background
                        :page-size="queryParams.pageSize"
                        :hide-on-single-page="false"
                        layout="prev, pager, next"
                        :total="total"
                        @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                    <el-empty v-if="recruitList.length==0" description="暂无招聘信息"></el-empty>
                </div>
            </div>
        </div>
        <div class="about_us">
            <div class="inner">
                <SubTitle class="map_title">
                    <template v-slot:title>联系我们</template>
                    <template v-slot:sub>CONTACT US</template>
                </SubTitle>
                <el-row :gutter="24">
                    <el-col :span="17">
                        <div class="address_jiewn" id="jiewen_map"></div>
                    </el-col>
                    <el-col :span="7">
                        <div class="info">
                            <div class="img_box">
                                <img src="~@/assets/images/lianxi_icon_weizhi.png" alt="">
                                <p>河南文杰信息技术有限公司</p>
                            </div>
                            <div class="info_item center">
                                <div class="title">公司地址</div>
                                <p>郑州市经开区第九大街经南二路<br>正商经开广场6号楼3层</p>
                            </div>
                            <div class="info_item center">
                                <div class="title">公司座机</div>
                                <p>0371-56062765</p>
                                <p style="margin-top: 10px">186-9734-2233</p>
                            </div>
                            <div class="info_item">
                                <div class="title">企业邮箱</div>
                                <p>mis01@qq.com</p>
                            </div>
                        </div>
                    </el-col>
                    </el-row>
            </div>
        </div>
        <el-dialog
            :visible.sync="successopen"
            width="540px"
            custom-class="form_success"
            center>
            <div class="content">
                <i class="el-icon-success" style="font-size:50px;color:#68BF7B;"></i>
                <p class="text">提交成功,我们会尽快与您联系.</p>
                <el-button type="primary" @click="closeSuccess">我知道了</el-button>
                <p class="footer_text"> <span>{{ timeNum }}秒</span>自动返回 </p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SubTitle from '../components/SubTitle'
import { getDict,postLiuyan } from '../api/aboutus'
export default {
    name:"AboutUS",
    components:{SubTitle},
    data(){
        return {
            isActive:0,
            form:{},
            rules:{
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确的联系方式', trigger: 'blur'}
                ],
                type: [
                    { required: true, message: '请选择意向菜单', trigger: 'change' }
                ],
                content: [
                    { required: true, message: '请填写留言', trigger: 'blur' }
                ],
            },
            total:0,
            queryParams:{
                pageSize:5,
                pageNum:1,
            },
            recruitList:[
                {
                    id:0,
                    post:'销售人员',
                    sub:0,
                    z:1,
                    address:['河南','郑州'],
                    postType:'销售类',
                    time:'2023-3-19'
                },{
                    id:1,
                    post:'销售人员',
                    sub:1,
                    z:1,
                    address:['河南','郑州'],
                    postType:'销售类',
                    time:'2023-3-19'
                },{
                    id:2,
                    post:'销售人员',
                    sub:0,
                    z:0,
                    address:['河南','郑州'],
                    postType:'销售类',
                    time:'2023-3-19'
                }
            ],
            successopen:false,
            timer:null,
            timeNum:3,
            typeList:[],
            loading:false,
        }
    },
    mounted(){
        if(this.$route.query.id==1){
            this.isActive = 1
        }
        this.initMap()
        this.getLiuyanleixing()
    },
    methods:{
        handleTabsChange(i){
            this.isActive = i
        },
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    postLiuyan(this.form).then(res=>{
                        this.diaglogTime()
                        this.successopen = true
                    }).finally(()=>{
                        this.loading = false
                    })
                    // alert('submit!');

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        closeSuccess(){
            clearInterval(this.timer)
            this.successopen = false
        },
        diaglogTime(){
            clearInterval(this.timer)
            this.timer = setInterval(()=>{
                if(this.timeNum<=0){
                    clearInterval(this.timer)
                    this.successopen = false
                    this.timeNum = 3
                }else{
                    this.timeNum --
                }
            },1000)
        },
        resetForm(formName){
            this.$refs[formName].resetFields();
        },
        handleCurrentChange(){

        },
        initMap(){
              //定义map变量，调用 TMap.Map() 构造函数创建地图
            var map = new AMap.Map('jiewen_map', {
                // pitch:15, // 地图俯仰角度，有效范围 0 度- 83 度
                zoom: 18,//级别
                center: [113.771044,34.718047],//中心点坐标
                viewMode: '3D'//使用3D视图
            })
            var marker = new AMap.Marker({
                position: new AMap.LngLat(113.771044,34.718047),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: '河南杰文信息技术有限公司'
            });
          // 信息窗体的内容
          var content = [
            "<div style='background: #FFFFFF; padding: 10px;text-align:center;'>河南杰文信息技术有限公司",
            "郑州市经开区第九大街经南二路<br>" +
            "正商经开广场6号楼3层</div>"
        ];

          // 创建 infoWindow 实例
          var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
            anchor: 'bottom-center',
            isCustom: true,  //使用自定义窗体
            content: content.join("<br>")  //传入 dom 对象，或者 html 字符串
          });

          // 打开信息窗体
          infoWindow.open(map, [113.771044,34.718047]);

            // 将创建的点标记添加到已有的地图实例：
            map.add(marker);
        },
        // 获取留言类型
        getLiuyanleixing(){
            getDict().then(res=>{
                this.typeList = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.title_tabs{
    display: flex;
    justify-content: center;
    position: relative;
    top: -50px;
    .title_inner{
        display: flex;
        .btn{
            position: relative;
            background-color: #fff;
            box-shadow: 0px 6px 27px 2px rgba(0,0,0,0.07);
            border-radius: 4px;
            width: 240px;
            height: 90px;
            cursor: pointer;
            transition: all .3s;
            img{
                opacity: 0;
                transition: all .3s;
            }
            .btn_inner{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
                height:100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    height: 32px;
                    width: 32px;
                    background: url('~@/assets/images/zhaopin_icon_liuyan_hei.png');
                }
                span{
                    margin-left: 17px;
                }
            }
            &:last-child{
                margin-left: 69px;
                i{
                    height: 32px;
                    width: 32px;
                    background: url('~@/assets/images/zhaopin_icon_zhaopin_hei.png');
                }
            }
            &.active{
                color: #fff;
                img{
                    opacity: 1;
                }
                i{
                    background: url('~@/assets/images/zhaopin_icon_liuyan_baise.png');
                }
                &:last-child{
                    i{
                        background: url('~@/assets/images/zhaopin_icon_zhaopin_bai.png');
                    }
                }
            }
        }

    }
}
.you_save{
    margin-top: 12px;
    padding-bottom: 60px;
    .leave_work_title{
        text-align: left;
        margin-bottom: 26px;
    }
    .remak{
        font-size: 18px;
        color: #999999;
        margin-bottom: 23px;
    }
    .xinxi_box{
        li{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            img{
                margin-right: 26px;
            }
            .xinxi{
                font-size: 14px;
            }
            .xinxi_title{
                color: #666666;
            }
            .xinxi_text{
                color: #999999;
            }
        }
    }
    .recruit{
        box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.07);
        padding: 31px 57px 61px;
        li{
            margin-bottom: 32px;
            border-bottom: 1px solid #EEEEEE;
            &:last-of-type{
                border-bottom: none;
            }
            .title{
                font-size: 20px;
                font-weight: 500;
                color: #333333;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .label{
                    line-height: 1;
                    font-size: 14px;
                    padding: 3px;
                    padding-left: 4px;
                    background: #FF6B6B;
                    color: #fff;
                    margin-right: 8px;
                }
            }
            .address_left,.info{
                font-size: 18px;
                color: #999999;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .line{
                    padding: 0 15px;
                }
            }
            .address{
                display: flex;
                justify-content: space-between;
                .time{
                    font-size: 16px;
                    color: #999999;
                }
            }
        }
        .page_box{
            text-align: center;
        }
    }
}
.about_us{
    .map_title{
        margin-bottom: 80px;
    }
    .address_jiewn{
        height: 608px;
    }
    .info{
        background: #EDEFF2;
        height: 608px;
        padding-top: 34px;
        padding-left: 36px;
        .img_box{
            margin-bottom: 30px;
            img{
                display: block;
                margin-bottom: 10px;
            }
            p{
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
                line-height: 1;
            }
        }
        .info_item{
            &.center{
                margin-bottom: 64px;
            }
            .title{
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #333333;
                margin-bottom: 26px;
            }
            p{
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
        }
    }
    margin-bottom: 162px;
}
</style>
<style lang="scss">
.form_success{
    height: 376px;
    background: url('~@/assets/images/tanchuang_bg.png');
    .el-dialog__headerbtn{
        display: none;
    }
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        padding: 0;
    }
    .content{
        padding-top: 86px;
        text-align: center;
        img{
            width: 69px;
            height: 69px;
            margin: auto;
            margin-bottom: 28px;
        }
        .text{
            font-size: 16px;
            font-weight: 400;
            color: #0085E8;
            line-height: 48px;
            margin-bottom: 29px;
        }
        .footer_text{
            margin-top:31px;
            font-size: 14;
            color: #999;
            span{
                color: #FF3030;
            }
        }
    }
}
</style>
