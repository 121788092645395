<template>
    <div class="sub_title">
        <h2 :style="{ color: titleColor }">
            <slot name="title"></slot>
        </h2>
        <h5 :class="{ color: subColor }">
            <slot name="sub"></slot>
        </h5>
    </div>
</template>

<script>
export default {
    props: {
        titleColor: {
            type: String,
            default: '#005FC6'
        },
        subColor: {
            type: String,
            default: '#999999'
        }
    }
}
</script>

<style lang="scss" scoped>
.sub_title {
    text-align: center;

    h2 {
        font-size: 44px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #005FC6;
        margin-bottom: 5px;
    }

    h5 {
        font-size: 22px;
        font-family: Arial;
        color: #999999;
    }
}
</style>